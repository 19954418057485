<template>
  <div class="publish-device">
    <Header />
    <el-form ref="form" :model="form" :rules="rules">
      <div class="container">
        <div class="w-1200">
          <div class="publish-device-title">修改出租设备</div>
          <div class="base-step">
            <div class="step-item" :class="{ active: true }">
              <span class="num">1</span><span class="name">描述商品详情</span>
            </div>
            <i class="divider" :class="{ active: false }"></i>
            <div class="step-item">
              <span class="num">2</span><span class="name">提交审核</span>
            </div>
            <i class="divider"></i>
            <div class="step-item">
              <span class="num">3</span><span class="name">平台审核</span>
            </div>
          </div>
          <section class="form">
            <div class="form-title">基本信息</div>
            <div class="form-main">
              <div class="form-item">
                <div class="form-label">选择分类</div>
                <el-form-item>
                  <el-cascader
                    ref="cascader"
                    style="width: 350px"
                    v-model="form.inputs.type_id"
                    :options="checkList"
                    :props="{
                      checkStrictly: true,
                      value: 'type_id',
                      label: 'type_name',
                    }"
                    clearable
                    @change="handleChange"
                  ></el-cascader>
                </el-form-item>
                <div class="search">
                  <el-form-item>
                    <el-input
                      v-model="keyWord"
                      type="text"
                      placeholder="搜索分类…"
                      class="input"
                    ></el-input>
                    <!-- <input type="text" placeholder="搜索分类…" class="input"> -->
                    <ul>
                      <li
                        v-for="item in searchResult"
                        :key="item.type_id"
                        @click="picktype(item.type_id)"
                      >
                        {{ item.type_name }}
                      </li>
                    </ul>
                  </el-form-item>
                  <div class="btn-search">搜索</div>
                </div>
              </div>
              <div class="form-item">
                <div class="form-label">标题名称</div>
                <el-form-item prop="inputs.name">
                  <el-input
                    v-model="form.inputs.name"
                    placeholder="请输入标题名称…"
                    style="width: 430px"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label">适用工况</div>
                <el-form-item prop="inputs.apply_working">
                  <el-checkbox-group v-model="form.inputs.apply_working">
                    <el-checkbox label="房建工程" name="check"></el-checkbox>
                    <el-checkbox label="市政工程" name="check"></el-checkbox>
                    <el-checkbox label="公路工程" name="check"></el-checkbox>
                    <el-checkbox label="水利工程" name="check"></el-checkbox>
                    <el-checkbox label="园林工程" name="check"></el-checkbox>
                    <el-checkbox label="桥隧工程" name="check"></el-checkbox>
                    <el-checkbox label="其他"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label">
                  租赁费<span class="btn-add-cost" @click="empty"
                    >添加费用+</span
                  >
                </div>
                <div class="cost-tag">
                  <span
                    class="cost-tag-item"
                    v-for="(item, index) in form.inputs.device_rental_fee_json"
                    :key="index"
                  >
                    {{ item.rental_fee + "/"
                    }}{{
                      item.rental_type == 0
                        ? "日"
                        : item.rental_type == 1
                        ? "月"
                        : item.rental_type == 2
                        ? "半年"
                        : "年"
                    }}
                    <i class="el-icon-close" @click="deleteI(index)"></i>
                  </span>
                </div>
              </div>
              <div class="form-item">
                <div class="form-label"><span class="icons">*</span>地址</div>
                <el-form-item prop="inputs.address">
                  <el-input id="test" v-model="form.inputs.address" placeholder="请输入地址…" style="width: 430px" @change="$refs.map.setPlace(form.inputs.address)"></el-input>
                  <div id="searchResultPanel" style="border: 1px solid #c0c0c0; width: 500px; height: auto; display: none"></div>
                </el-form-item>
                <Map class="map" style="height: 300px" ref="map" @getAddress="getAddress" :searchAddress="form.inputs.address"> </Map>
              </div>
              <div class="form-item">
                <div class="form-label">设备数量</div>
                <el-form-item prop="inputs.num">
                  <el-input
                    type="number"
                    v-model="form.inputs.num"
                    placeholder="请输入…"
                    style="width: 100px"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label">展示图片</div>
                <el-form-item prop="inputs.show_img">
                  <el-upload
                    action="http://114.67.170.224:21880/api/upload"
                    list-type="picture-card"
                    :on-success="handleAvatarSuccess1"
                    :limit="1"
                    :file-list="form.inputs.show_img"
                    :on-remove="handleRemove1"
                    :on-exceed="limitCheck"
                  >
                    <i class="el-icon-plus"></i>
                    <div>添加上传图片</div>
                  </el-upload>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label">列表图片</div>
                <el-form-item prop="inputs.other_img">
                  <el-upload
                    action="http://114.67.170.224:21880/api/upload"
                    list-type="picture-card"
                    :on-success="handleAvatarSuccess2"
                    :limit="9"
                    :on-exceed="limitCheck"
                    :file-list="form.inputs.other_img"
                    :on-remove="handleRemove2"
                  >
                    <i class="el-icon-plus"></i>
                    <div>添加上传图片</div>
                  </el-upload>
                </el-form-item>
              </div>
            </div>
          </section>
          <section class="form">
            <div class="form-title">参数信息</div>
            <div class="form-main">
              <div class="f-flex">
                <div class="form-item">
                  <div class="form-label">设备品牌</div>
                  <el-form-item prop="inputs.param_info.brand">
                    <el-input
                      v-model="form.inputs.param_info.brand"
                      placeholder="请输入品牌名称…"
                      style="width: 350px"
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="form-item" style="margin-left: 20px">
                  <div class="form-label">设备类型</div>
                  <el-form-item prop="inputs.param_info.type">
                    <el-input
                      v-model="form.inputs.param_info.type"
                      placeholder="请输入设备类型…"
                      style="width: 350px"
                    >
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="form-item">
                <div class="form-label">备件</div>
                <el-form-item prop="inputs.param_info.sparePart">
                  <el-input
                    v-model="form.inputs.param_info.sparePart"
                    placeholder="请输入备件…"
                    style="width: 350px"
                  >
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </section>
          <section class="form">
            <div class="form-title">参数信息</div>
            <div class="form-main">
              <div class="form-item">
                <div class="form-label">详细信息</div>
                <!-- <div style="border: 1px solid #d3d3d3; height: 290px; border-radius: 4px; text-align: center; line-height: 290px">富文本框</div> -->
                <my-editor
                  @gethtml="gethtml"
                  :detail_info="form.inputs.detail_info"
                  :key="timer"
                ></my-editor>
              </div>
              <div class="form-item">
                <div class="form-label">详情图片</div>
                <el-form-item>
                  <el-upload
                    action="http://114.67.170.224:21880/api/upload"
                    list-type="picture-card"
                    :on-success="handleAvatarSuccess3"
                    :file-list="form.inputs.detail_img"
                    :on-remove="handleRemove3"
                  >
                    <i class="el-icon-plus"></i>
                    <div>添加上传图片</div>
                  </el-upload>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label">
                  资质证明（设备产权证，若无需上传承诺书）
                </div>
                <el-form-item>
                  <el-upload
                    action="http://114.67.170.224:21880/api/upload"
                    list-type="picture-card"
                    :on-success="handleAvatarSuccess4"
                    :limit="1"
                    :on-exceed="limitCheck"
                    :file-list="form.inputs.proof_assets_img"
                    :on-remove="handleRemove4"
                  >
                    <i class="el-icon-plus"></i>
                    <div>添加上传图片</div>
                  </el-upload>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label">人员特殊驾驶证</div>
                <el-form-item>
                  <el-upload
                    action="http://114.67.170.224:21880/api/upload"
                    list-type="picture-card"
                    :on-success="handleAvatarSuccess5"
                    :limit="1"
                    :on-exceed="limitCheck"
                    :file-list="form.inputs.special_driver_img"
                    :on-remove="handleRemove5"
                  >
                    <i class="el-icon-plus"></i>
                    <div>添加上传图片</div>
                  </el-upload>
                </el-form-item>
              </div>
            </div>
          </section>
          <section class="form-btn-group">
            <el-button
              class="btn-item btn-border"
              :plain="true"
              @click="$router.go(-1)"
              >取消</el-button
            >
            <el-button class="btn-item" :plain="true" @click="goEnd"
              >申请修改</el-button
            >
          </section>
        </div>
      </div>
      <Footer />
      <right-nav />
      <!-- 添加费用s -->
      <el-dialog
        title="添加费用"
        :show-close="false"
        :visible.sync="addCostDialogVisible"
        width="500px"
        class="add-cost-dialog"
      >
        <div class="add-cost-form">
          <el-form-item>
            <el-input
              v-model="a1.rental_fee"
              placeholder="请输入金额"
              style="width: 170px"
              type="number"
            ></el-input>
            <el-select
              class="select"
              v-model="a1.rental_type"
              placeholder="请选择"
              style="width: 130px; margin-left: 34px"
            >
              <!-- 130px 34px -->
              <el-option label="日" value="0"></el-option>
              <el-option label="月" value="1"></el-option>
              <el-option label="半年" value="2"></el-option>
              <el-option label="年" value="3"></el-option>
            </el-select>
          </el-form-item>
          <section class="form-btn-group">
            <span class="btn-item btn-border" @click="clean">取消</span>
            <span class="btn-item" @click="addTag">确定</span>
          </section>
        </div>
      </el-dialog>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span class="back">提交成功,请选择返回页面</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" class="backhome" @click="gohome"
            >返回首页</el-button
          >
          <el-button type="primary" class="backDevice" @click="goMyDevice"
            >我的设备</el-button
          >
        </span>
      </el-dialog>
    </el-form>

    <!-- 添加费用e -->
    <!-- 申请发布页面跳转 -->
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import RightNav from "@/components/rightNav";
import { typeList } from "@/api/publish.js";
import kits from "@/kits/index.js";
import myEditor from "../publishNeed/myEditor.vue";
import { getupdateDevice, updateDevice } from "@/api/myorder";
import Map from '@/components/map.vue'
export default {
  name: "publishDevice",
  components: {
    Header,
    Footer,
    RightNav,
    myEditor,
    Map
  },
  data() {
    return {
      timer:'',
      html: "<p>hellosdadssdds</p>",
      keyWord: "",
      searchList: [],
      /* tag标签 */
      a1: {
        rental_fee: "",
        rental_type: null,
      },
      dialogVisible: false,
      dynamicTags: ["2000/日"],
      addCostDialogVisible: false,
      checkList: [],
      input: "",
      detail_imgs: [],
      other_imgs: [],
      show_imgs: [],
      proof_assets_imgs: [],
      special_driver_img: [],
      id: "",
      b1: "",
      form: {
        inputs: {
          // consumer_id: 2,
          name: "",
          type_id: null,
          apply_working: [],
          address: "",
          num: null,
          show_img: "",
          other_img: [],
          device_config_id: '',
          param_info: {
            brand: "",
            type: "",
            sparePart: "",
          },
          detail_info: "",
          detail_img: [],
          proof_assets_img: "",
          special_driver_img: "",
          longitude: "136",
          latitude: "356",
          device_rental_fee_json: [],
        },
      },
      rules: {
        inputs: {
          name: [
            { required: true, message: "请输入标题名称", trigger: "blur" },
          ],
          apply_working: [
            {
              type: "array",
              required: true,
              message: "请至少选择一个适用工况",
              trigger: "change",
            },
          ],
          address: [{ required: true, message: "请输入地址", trigger: "blur" }],
          num: [
            { required: true, message: "请输入数量", trigger: "blur" },
            { min: 1, max: 4, message: "1~9999", trigger: "blur" },
          ],
          show_img: [
            { required: true, message: "请上传展示图片", trigger: "change" },
          ],
          other_img: [
            {
              required: true,
              message: "请上列表示图片，最多上传9张图片",
              trigger: "change",
            },
          ],
          param_info: {
            brand: [
              { required: true, message: "请输入设备品牌", trigger: "blur" },
            ],
            type: [
              { required: true, message: "请输入设备类型", trigger: "blur" },
            ],
            sparePart: [
              { required: true, message: "请输入备件", trigger: "blur" },
            ],
          },
        },
      },
    };
  },
  computed: {
    searchResult() {
      if (this.keyWord) {
        return this.searchList.filter((value) => {
          return value.type_name.includes(this.keyWord);
        });
      } else {
        return "";
      }
    },
  },
  created() {
    this.reviseDevice();
    this.getdata();
    typeList()
      .then((res) => {
        if (res.status != 200) return;
        this.searchList = res.data;
        this.checkList = kits.arr.tree_set(res.data, "parent_id", "type_id");
      })
      .catch((err) => {
        return err;
      });
  },
  mounted() {
    this.searchResult1()
  },
  methods: {
    async reviseDevice() {
      this.id = this.$route.query.id;
      let res = await getupdateDevice({ inputs: { device_lease_id: this.id } });

      this.form.inputs = res.data;

      this.device = res.data;

      this.form.inputs.show_img = [{ url: kits.img.url(this.device.show_img) }];

      var obj = JSON.parse(this.form.inputs.param_info);

      this.form.inputs.param_info = obj;

      let fee = JSON.parse(this.device.device_rental_fee_json);

      this.form.inputs.device_rental_fee_json = fee;

      if (this.device.proof_assets_img == null) {
        this.form.inputs.proof_assets_img = [];
      } else if (this.device.proof_assets_img == "") {
        this.form.inputs.proof_assets_img = [];
      } else if (this.device.proof_assets_img == []) {
        this.form.inputs.proof_assets_img = [];
      } else {
        this.form.inputs.proof_assets_img = [
          { url: kits.img.url(this.device.proof_assets_img) },
        ];
      }

      if (this.device.special_driver_img == null) {
        this.form.inputs.special_driver_img = [];
      } else if (this.device.special_driver_img == "") {
        this.form.inputs.special_driver_img = [];
      } else if (this.device.special_driver_img == []) {
        this.form.inputs.special_driver_img = [];
      } else {
        this.form.inputs.special_driver_img = [
          { url: kits.img.url(this.device.special_driver_img) },
        ];
      }

      let time = this.device.apply_working.split(",");
      for (let i = 0; i <= time.length - 1; i++) {
        if (time[i] == 1) {
          time[i] = "房建工程";
        } else if (time[i] == 2) {
          time[i] = "市政工程";
        } else if (time[i] == 3) {
          time[i] = "公路工程";
        } else if (time[i] == 4) {
          time[i] = "水利工程";
        } else if (time[i] == 5) {
          time[i] = "园林工程";
        } else if (time[i] == 6) {
          time[i] = "桥隧工程";
        } else if (time[i] == 7) {
          time[i] = "其他";
        }
      }
      this.form.inputs.apply_working = time;

      let detail_imgs = JSON.parse(this.device.detail_img);
      this.form.inputs.detail_img = [];
      for (let m = 0; m <= detail_imgs.length - 1; m++) {
        this.form.inputs.detail_img.push({ url: kits.img.url(detail_imgs[m]) });
      }

      let other_imgs = JSON.parse(this.device.other_img);
      this.form.inputs.other_img = [];
      for (let i = 0; i <= other_imgs.length - 1; i++) {
        this.form.inputs.other_img.push({ url: kits.img.url(other_imgs[i]) });
      }
      this.timer = new Date().getTime();
    },
    async getdata() {
      let res1 = await getupdateDevice({
        inputs: { device_lease_id: this.id },
      });

      if( res1.data.proof_assets_img == null){
        res1.data.proof_assets_img = ""
      }
      if( res1.data.special_driver_img == null){
        res1.data.special_driver_img = ""
      }
      res1.data.param_info = JSON.parse(res1.data.param_info);

      res1.data.other_img = JSON.parse(res1.data.other_img);

      res1.data.detail_img = JSON.parse(res1.data.detail_img);

      res1.data.device_rental_fee_json = JSON.parse(
        res1.data.device_rental_fee_json
      );

      this.b1 = JSON.stringify(res1.data);
    },
    handleRemove1() {
      this.show_imgs = [];
      this.form.inputs.show_img = [];
    },
    handleRemove2(file) {
      let removefile1 = file;
      let removearr1 = [];
      removearr1.push(removefile1);
      for (let i = 0; i < removearr1.length; i++) {
        for (let j = 0; j < this.form.inputs.other_img.length; j++) {
          if (removearr1[i] == this.form.inputs.other_img[j]) {
            this.form.inputs.other_img.splice(j, 1);
          }
        }
      }
    },
    handleRemove3(file) {
      let removefile1 = file;
      let removearr1 = [];
      
      removearr1.push(removefile1);
      for (let i = 0; i < removearr1.length; i++) {
        for (let j = 0; j < this.form.inputs.detail_img.length; j++) {
          if (removearr1[i] == this.form.inputs.detail_img[j]) {
            this.form.inputs.detail_img.splice(j, 1);
          }
        }
      }
    },
    handleRemove4() {
      this.form.inputs.proof_assets_img = [];
      console.log('dsadsa',this.form.inputs.proof_assets_img);
    },
    handleRemove5() {
      this.form.inputs.special_driver_img = [];
    },
    // 取消
    cancel(form) {
      //重置表单数据
      this.$refs[form].resetFields();
    },
    // 申请发布
    async goEnd() {
      const confirm = await this.$confirm(
        "是否确认信息无误, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((error) => error);
      if (confirm !== "confirm") {
        return this.$message.info("您已取消修改");
      }
      this.apply();
    },
    forms() {
      for (let i = 0; i <= this.form.inputs.detail_img.length - 1; i++) {
        let a = this.form.inputs.detail_img[i].url.split(
          "http://114.67.170.224:21880/api/view?url="
        );
        this.detail_imgs.push(a[a.length - 1]);
      }

      for (let i = 0; i <= this.form.inputs.other_img.length - 1; i++) {
        let a = this.form.inputs.other_img[i].url.split(
          "http://114.67.170.224:21880/api/view?url="
        );
        this.other_imgs.push(a[a.length - 1]);
      }

      let a = this.form.inputs.show_img[0].url.split(
        "http://114.67.170.224:21880/api/view?url="
      );
      this.form.inputs.show_img = a[1];

      if (this.form.inputs.proof_assets_img == "") {
        this.form.inputs.proof_assets_img = "";
        console.log('dsadasasd', this.form.inputs.proof_assets_img);
      } else {
        let b = this.form.inputs.proof_assets_img[0].url.split(
          "http://114.67.170.224:21880/api/view?url="
        );
        this.form.inputs.proof_assets_img = b[1];
      }

      if (this.form.inputs.special_driver_img == "") {
        this.form.inputs.special_driver_img = "";
      } else {
        let c = this.form.inputs.special_driver_img[0].url.split(
          "http://114.67.170.224:21880/api/view?url="
        );
        this.form.inputs.special_driver_img = c[1];
      }

      this.form.inputs.other_img = this.other_imgs;
      this.form.inputs.detail_img = this.detail_imgs;

      for (let i = 0; i <= this.form.inputs.apply_working.length - 1; i++) {
        if (this.form.inputs.apply_working[i] == "房建工程") {
          this.form.inputs.apply_working[i] = 1;
        } else if (this.form.inputs.apply_working[i] == "市政工程") {
          this.form.inputs.apply_working[i] = 2;
        } else if (this.form.inputs.apply_working[i] == "公路工程") {
          this.form.inputs.apply_working[i] = 3;
        } else if (this.form.inputs.apply_working[i] == "水利工程") {
          this.form.inputs.apply_working[i] = 4;
        } else if (this.form.inputs.apply_working[i] == "园林工程") {
          this.form.inputs.apply_working[i] = 5;
        } else if (this.form.inputs.apply_working[i] == "桥隧工程") {
          this.form.inputs.apply_working[i] = 6;
        } else if (this.form.inputs.apply_working[i] == "其他") {
          this.form.inputs.apply_working[i] = 7;
        }
      }
      let permission = this.form.inputs.apply_working.join(",");
      this.form.inputs.apply_working = permission;
    },
    apply() {
      this.forms();
      if (JSON.stringify(this.form.inputs) === this.b1) {
        this.$message.warning("没有修改,请勿提交");
        setTimeout(function () {
          location.reload();
          window.scroll(0, 0);
        }, 1500);
      } else {
        updateDevice(this.form).then((res) => {
          res.data = this.form;
          if (res.status !== 200) {
          this.$message.error('申请失败')
        }
        });
        this.$message.success('修改成功')
        this.$router.push({path:'/personalCenter/myDevice'})
      }
      
      
    },
    // 超出文件总数时，触发
    limitCheck() {
      this.$message.warning("超出上传图片数量");
    },
    // 返回首页
    gohome() {
      this.dialogVisible = false;
      this.$router.push("/home");
    },
    // 返回我的设备
    goMyDevice() {
      this.dialogVisible = false;
      this.$router.push("/personalCenter/myDevice");
    },
    // 展示图片
    handleAvatarSuccess1(res) {
      this.form.inputs.show_img.push({ url: kits.img.url(res.data.value) });
    },

    handleAvatarSuccess2(res) {
      this.form.inputs.other_img.push({ url: kits.img.url(res.data.value) });
    },
    handleAvatarSuccess3(res) {
      this.form.inputs.detail_img.push({ url: kits.img.url(res.data.value) });
    },
    handleAvatarSuccess4(res) {
      this.form.inputs.proof_assets_img.push({
        url: kits.img.url(res.data.value),
      });
    },
    handleAvatarSuccess5(res) {
      this.form.inputs.special_driver_img.push({
        url: kits.img.url(res.data.value),
      });
    },
    addTag() {
      this.form.inputs.device_rental_fee_json.push(this.a1);
      this.form.inputs.device_rental_fee_json = JSON.parse(
        JSON.stringify(this.form.inputs.device_rental_fee_json)
      );
      this.addCostDialogVisible = false;
    },
    // 取消添加金额
    clean() {
      this.a1.rental_type = "";
      this.a1.rental_fee = "";
      this.addCostDialogVisible = false;
    },
    // 清空添加金额数据
    empty() {
      this.addCostDialogVisible = true;
      this.$nextTick(() => {
        (this.a1.rental_fee = ""), (this.a1.rental_type = "");
      });
    },
    //删除金额
    deleteI(index) {
      this.form.inputs.device_rental_fee_json.splice(index, 1);
    },
    // 选择器变化事件
    handleChange() {
      let nodesInfo = this.$refs.cascader.getCheckedNodes();
      this.form.inputs.type_id = nodesInfo[0].data.type_id;
    },
    searchResult1() {
            var ac = new window.BMapGL.Autocomplete({ input: "test" });
            console.log("ac", ac);
			let that = this
            ac.addEventListener("onhighlight", function (e) {
                //鼠标放在下拉列表上的事件
                var str = "";
                var _value = e.fromitem.value;
                var value = "";
                if (e.fromitem.index > -1) {
                    value =
                        _value.province +
                        _value.city +
                        _value.district +
                        _value.street +
                        _value.business;
                }
                str =
                    "FromItem<br />index = " +
                    e.fromitem.index +
                    "<br />value = " +
                    value;
                value = "";
                if (e.toitem.index > -1) {
                    _value = e.toitem.value;
                    value =
                        _value.province +
                        _value.city +
                        _value.district +
                        _value.street +
                        _value.business;
                }
                str +=
                    "<br />ToItem<br />index = " +
                    e.toitem.index +
                    "<br />value = " +
                    value;
                document.getElementById("searchResultPanel").innerHTML = str;
            });

            var myValue;
            ac.addEventListener("onconfirm", function (e) {
                //鼠标点击下拉列表后的事件
				document.getElementById("searchResultPanel").innerHTML = ''
                var _value = e.item.value;
                myValue =
                    _value.province +
                    _value.city +
                    _value.district +
                    _value.street +
                    _value.business;
                document.getElementById("searchResultPanel").innerHTML =
                    "onconfirm<br />index = " +
                    e.item.index +
                    "<br />myValue = " +
                    myValue;
				that.$refs.map.setPlace(myValue)
            });
        },
    // 搜索选择分类
    picktype(id) {
      (this.form.inputs.type_id = id), (this.keyWord = "");
    },
    // 回去富文本数据
    gethtml(html) {
      this.form.inputs.detail_info = html;
    },
    getAddress(address) {
      this.form.inputs.address = address
    },
  },
};
</script>

<style scoped>
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
  border-radius: 10px;
}

.backhome {
  margin-right: 150px;
}

.backDevice {
  margin-right: 30px;
}

.back {
  font-size: 26px;
}

.search ul {
  width: 270px;
  min-height: 0;
  max-height: 240px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.search ul li {
  width: 270px;
  height: 30px;
  background-color: #f8f8ff;
  line-height: 30px;
  border-bottom: 1px solid #cfcfcf;
  padding-left: 15px;
}
</style>
